@use "_global/import" as *;

.l-fv {
  @extend .container;
}

.c-fv {
  position: relative;
  #svg_fv_pc,
  #svg_fv_sp {
    width: 100%;
  }
  .c-pc-fv {
    display: none;
    #svg_fv_pc {
      display: none;
    }
    @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
      display: block;
      position: relative;
      #img_fv_pc_bg {
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(../img/index_fv_pc_bg.jpg);
        background-size: 100%;
      }
      #svg_fv_pc {
        display: block;
      }
    }
  }
  .c-sp-fv {
    display: block;
    position: relative;
    #img_fv_sp_bg {
      z-index: -1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(../img/index_fv_sp_bg.jpg);
      background-size: 100%;
    }
    #svg_fv_sp {
      display: block;
    }
    @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
      display: none;
      #svg_fv_sp {
        display: none;
      }
    }
  }
}

.p-fv-num {
  width: 100%;
  position: absolute;
  bottom: 48.5%;
  padding-left: 7%;
  @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
    bottom: 7.2%;
    padding-left: 12%;
  }
}

#target {
  font-family: serif,'Frank Ruhl Libre',"Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho",;
  font-weight: 400;
  font-size: vw(165);
  letter-spacing: vw(-15);
  line-height: 1;
  @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
    font-size: vw(60);
    letter-spacing: vw(-5);
  }
  @include mq(lg) {
    font-size: 155px;
    letter-spacing: -15px;
  }
}

body {
  @extend .sans-serif;
}

.l-data-wrap {
  @extend .container;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  &::before {
    @extend .container;
    display: block;
    max-width: 100%;
    padding-top: 165px;
    margin: 0 auto;
    background-color: #DCDEE5;
    content: "";
    @include mq() {
      padding-top: 30px;
    }
  }
  .l-data {
    margin: 0 auto;
    padding: 0 15px;
    background-color: #fff;
    @extend .container;
    max-width: 1080px;
    .c-data {
      @include flex;
      @include flexwrap;
      // overflow: hidden;
      .c-data-item:last-child {
        padding-left: 20px;
        @include mq() {
          padding-left: 0;
        }
      }
      .c-date-item-contents {
        margin: 100px 0;
        p {
          font-size: 14px;
        }
        }
      .row {
        margin-bottom: 30px;
        @include mq() {
          margin-bottom: vw(30);
        }
      }
    }
  }
}

.c-index-headeing1 {
  margin-top: 80px;
  padding-left: 14%;
  @include mq() {
    padding-left: 31%;
  }
  img {
    width: 100%;
    max-width: 230px;
    height: auto;
  }
  &::before {
    content: "";
    background-image: url(../img/index_heading.svg);
    background-repeat: no-repeat;
    position: absolute;
    width: 92px;
    height: 255px;
    top: -20px;
    left: 0;
    @include mq() {
      background-size: contain;
      top: -7px;
      width: 63px;
      height: 174px;
    }
  }
}

.p-arrow-1 {
  position: relative;
  display: inline-block;
  background: #9C83A5;
  color: #fff;
  line-height: 1.25;
  font-size: 16px;
  padding: 5px 15px 5px 30px;
  height: 30px;
  @include mq() {
    font-size: calc(vw(16)*1.5);
    padding: calc(vw(5)*1.5) calc(vw(15)*1.5) calc(vw(5)*1.5) calc(vw(30)*1.5);
    height: calc(vw(30)*1.5);
    margin-bottom: vw(10);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    vertical-align: middle;
    left: 100%;
    width: 15px;
    height: 15px;
    border: 15px solid transparent;
    border-left: 15px solid #9C83A5;
    @include mq() {
      width: calc(vw(15)*1.5);
      height: calc(vw(15)*1.5);
      border: calc(vw(15)*1.5) solid transparent;
      border-left: calc(vw(15)*1.5) solid #9C83A5;
    }
  }
}

.c-data-item {
  margin-bottom: 40px;
  font-size: 18px;
  @include mq() {
    font-size: calc(vw(18)*1.5);
    margin-bottom: vw(40);
  }
  h3 {
    margin-bottom: 1rem;
  }
  p {
    font-size: 16px;
    @include mq() {
      font-size: calc(vw(16)*1.5);
    }
  }
}

.l-data .c-data .row {
  &:last-child {
    margin-bottom: 90px;
    @include mq() {
      margin-bottom: vw(90);
    }
  }
  &:last-child .c-data-item {
    margin-bottom: 0;
  }
}

.l-bottom-nav {
  margin: 55px auto;
  @include mq() {
    margin: vw(55) auto;
  }
  .p-header-row {
    margin-top: 0;
    justify-content: center;
  }
  .p-navbar-main-wrap {
    padding-right: 0;
  }
}

.l-footer {
  @extend .container;
  .l-footer-innner {
    @extend .container;
    max-width: 1080px;
    @include mq() {
      max-width: vw(680);
    }
  }
  .c-footer-sub {
    background-color: #F2F2F2;
    padding: 100px 0 25px;
    @include mq() {
      padding: vw(100) 0 vw(25);
    }
    .c-footer-sub-inner {
      @include flex;
      flex-direction: column;
      margin-bottom: 90px;
      @include mq() {
        margin-bottom: vw(90);
      }
      @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
        @include flexaround;
        flex-direction: row;
      }
    }
    .p-emblem {
      text-align: center;
      margin: 0 auto;
      img {
        width: 53px;
        height: auto;
      }
      p {
        font-size: 14px;
        @include mq() {
          font-size: vw(14)*1.5;
        }
      }
    }
  }
}

.p-footer-sub-item {
  margin: 20px auto 0;
  text-align: center;
  background-color: #fff;
  max-width: 513px;
  width: 100%;
  padding: 50px 0;
  position: relative;
  @include pri-mq-up(map-get($grid-breakpoints, 'md')) {
    margin: 20px 20px 0;
  }
  .p-ribbon {
    position: absolute;
    left: -6px;
    top: 14px;
  }
  .p-stretched-link {
    color:inherit;
    &::after {
      content: "";
      position: absolute;
      pointer-events: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}

.c-retina-img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.p-under-fv {
  background-color: #fcf9fb;
  margin-top: -3px;
  @include mq() {
    margin-bottom: 0;
  }
  .p-fv-links {
    background: rgb(172,132,171);
    background: linear-gradient(0deg, rgba(192,133,167,1) 0%, rgba(172,132,171,1) 100%);
    max-width: 1118px;
    margin: 0 0 0 auto;
    @include flex;
    @include mq() {
      flex-direction: column;
    }
    .p-fv-links-btn-wrap {
      background-color: #fff;
      .p-fv-links-btn {
        width: 494px;
        @include mq() {
          width: 100%;
        }
      }
      &:first-of-type {
        margin-right: -1px;
        @include mq() {
          margin-right: 0;
        }
      }
    }
    img {
      height: 100%;
      width: 100%;
      @include mq() {
        height: auto;
        width: 100%;
      }
      @media print {
        height: unset;
      }
    }
  }
}

.p-research-data {
  margin-top: 80px;
  .p-research-data-title {
    font-size: 33px;
    font-weight: bold;
    margin-top: 80px;
    @include mq() {
      margin-top: 0;
      font-size: vw(33);
    }
  }
  .p-research-data-txt {
    margin-top: 65px;
    font-size: 14px;
    line-height: 2.2;
    @include mq() {
      margin-top: vw(65);
      font-size: vw(14)*1.5;
    }
  }
  .p-research-data-img {
    margin-left: 55px;
    @include mq() {
      margin-top: 40px;
      margin-left: 0;
    }
  }
}

.p-intestine {
  margin-top: 100px;
  padding-top: 25px;
  @include mq() {
    margin-top: vw(170);
    padding-top: vw(25);
  }
  .p-intestine-title {
    position: relative;
    @include mq() {
      padding: 0 50px;
      img {
        width: 100%;
        height: auto;
      }
    }
    h3 {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 57px;
      line-height: 1.35;
      padding: 55px 90px;
      white-space: nowrap;
      @include mq() {
        font-size: vw(57);
        padding: vw(55) vw(90);
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 90px;
        width: 55px;
        border-top: 4px solid #9C83A5;
        @include mq() {
          width: vw(55);
          left: vw(90);
        }
      }
    }
  }
  .p-intestine-txt {
    @include mq() {
      margin-top: 25px;
    }
    h4 {
      font-size: 22px;
      line-height: 2.2;
      @include mq() {
        font-size: vw(22);
      }
    }
    p {
      font-size: 14px;
      line-height: 2.2;
      @include mq() {
        font-size: vw(14)*1.5;
      }
    }
  }
}

.p-pylori {
  margin: 125px 0 130px;
  align-items: center;
  @include mq() {
    margin: vw(195) 0 vw(130);
  }
  .p-pylori-title  {
    position: relative;
    bottom: 0;
    right: 0;
    font-size: 57px;
    line-height: 1.35;
    padding: 55px 0 0;
    margin-bottom: 50px;
    white-space: nowrap;
    @media screen and (max-width: 1105px) {
      font-size: vw(40);
    }
    @include mq() {
      white-space: unset;
      padding: vw(55) 0 0;
      margin-bottom: vw(50);
      font-size: vw(57);
    }
    @media print {
      font-size: 40px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 55px;
      border-top: 4px solid #9C83A5;
      @include mq() {
        width: vw(55);
      }
    }
  }
  .p-pylori-txt {
    h4 {
      font-size: 24px;
      @include mq() {
        font-size: vw(24);
      }
    }
    p {
      margin-top: 50px;
      font-size: 14px;
      line-height: 2.14;
      @include mq() {
        margin-top: vw(50);
        font-size: vw(14)*1.5;
      }
    }
  }
  .p-pylori-figure-up {
    align-items: center;
    @include mq() {
      margin-top: 25px;
    }
    &-img {
      text-align: center;
      img {
        width: 100%;
        height: auto;
        @include mq() {
          width: 80%;
        }
      }
    }
    p {
      width: 100%;
      font-size: 12px;
      line-height: 1.6;
      @include mq() {
        font-size: vw(12)*1.5;
      }
    }
  }
  .p-pylori-figure-low {
    align-items: center;
    @include mq() {
      margin-top: 25px;
    }
    img {
      margin-left: 30px;
      width: 100%;
      max-width: 257px;
      height: auto;
      @include mq() {
        margin: 0;
      }
    }
    p {
      font-size: 14px;
      line-height: 2.14;
      margin: 0 30px;
      @include mq() {
        font-size: vw(14)*1.5;
        margin: 0 vw(30);
      }
    }
  }
}

.p-index-heading {
  position: relative;
  &::before {
    @include mq() {
      margin-top: 63px;
    }
  }
  &::after {
    content: "";
    background-image: url(../img/index_heading.svg);
    background-repeat: no-repeat;
    position: absolute;
    width: 92px;
    height: 255px;
    top: 40px;
    right: 115px;
    @include mq() {
      background-size: contain;
      top: 0px;
      right: 37px;
      width: 63px;
      height: 174px;
    }
  }
}

.p-to-qa {
  &-inner {
    margin-top: 240px;
    @include mq() {
      @include flexcenter;
      margin: 123px auto 0;
    }
  }
  &-img {
    img {
      width: 100%;
      height: auto;
    }
  }
  &-list {
    padding-left: 70px;
    @include mq() {
      padding: 0;
    }
    ul {
      list-style: none;
      @include mq() {
        padding-left: vw(70);
      }
      li {
        padding: 25px 0;
        @include mq() {
          padding: vw(25) 0;
        }
      }
      li + li {
        border-top: 2px dotted #C9705F;
      }
      a {
        margin-left: 0;
        text-indent: -70px;
        color: inherit;
        @include mq() {
          text-indent: vw(-70);
        }
        p {
          position: relative;
          display: inline-block;
          font-size: 21px;
          line-height: 1.4;
          color: #59525D;
          @include mq() {
            font-size: vw(21);
          }
          .p-to-qa-q {
            display: inline;
            font-size: 30px;
            color: #C9705F;
            margin-right: 24px;
            @include mq() {
              font-size: vw(30);
              margin-right: vw(24);
            }
          }
        }
        &:hover .p-to-qa-txt{
          text-decoration: underline;
        }
      }
    }
  }
}

.p-to-detail {
  margin-top: 125px;
  position: relative;
  @include mq() {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      border-top: 20px solid #F4B8AD;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -40px;
      width: 100%;
      border-top: 20px solid #F4B8AD;
    }
  }
  &-inner {
    @include flexaround;
    border: 20px solid #F4B8AD;
    border-radius: 70px;
    position: relative;
    @include mq() {
      padding: 0;
      border: none;
    }
  }
  &-ribbon {
    position: absolute;
    top: -47px;
    right: 7%;
    @include mq() {
      top: -25px;
      right: 9.5%;
      img {
        width: 120px;
        height: auto;
      }
    }
  }
  &-logo {
    padding-top: 110px;
    h3 {
      @include mq() {
        text-align: center;
      }
      img {
        width: 100%;
        height: auto;
        @include mq() {
          width: 77%;
        }
      }
    }
  }
  &-txt  {
    margin-top: 60px;
    width: 90%;
    @include mq() {
      width: 100%;
      text-align: center;
      margin-bottom: 60px;
    }
    p {
      font-size: 14px;
      line-height: 2.1;
      @include mq() {
        text-align: left;
        width: 77%;
        display: inline-block;
      }
    }
  }
  &-img {
    margin: 20px 0 45px;
    img {
      width: 100%;
      height: auto;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  &-link-list {
    padding-top: 145px;
    padding-left: 0;
    list-style: none;
    @include mq() {
      padding-top: 0;
    }
    li + li {
      border-top: dotted 2px #96786F;
      @include mq() {
        border-top: none;
      }
    }
  }
  &-link {
    color: #59525D;
    font-weight: lighter;
    display: flex;
    align-items: center;
    font-size: 25px;
    width: 100%;
    @include mq() {
      display: none;
    }
    a:hover {
      color: #59525D;
    }
    img {
      width: 170px;
      height: 170px;
      margin-left: -85px;
      display: inline-block;
    }
  }
  &-link-sp {
    color: #59525D;
    font-weight: lighter;
    display: flex;
    align-items: center;
    font-size: vw(25);
    width: 100%;
    @include mq(pc) {
      display: none;
    }
    @media print {
      display: none;
    }
    a:hover {
      color: #59525D;
    }
    img {
      width: vw(200);
      height: vw(200);
      margin-left: 0;
      display: inline-block;
    }
  }
}

.p-to-movie {
  margin-top: 220px;
  @include mq() {
    margin-top: vw(220);
  }
  &-inner {
    @include flexcenter;
  }
  h3 {
    font-size: 39px;
    color: #59525D;
    text-align: center;
  }
  // &-item {
  //   padding: 10px;
  //   @include mq {
  //     padding: 0;
  //   }
  // }
  &-frame {
    position: relative;
    width:100%;
    height:0;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &-border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: 1px solid #707070 !important;
      outline-offset: -1px;
      pointer-events: none;
      @media print {
        outline: unset;
      }
    }
  }
}

.p-topics {
  margin-top: 180px;
  margin-bottom: 170px;
  @include mq() {
    margin-top: 80px;
    margin-bottom: 60px;
  }
}

.p-topics-inner {
  padding: 0;
  flex-direction: column;
}

.p-topics-title {
  font-size: 32px;
  line-height: 1;
}

.p-topics-content {
  margin-top: 51.4px;
}

.p-topics-body {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #707070;
  color: #101010;
}

.p-topics-info {
  margin-bottom: 16.4px;
}

@media (min-width: 576px) {
  .p-topics-info {
    margin-bottom: 0;
  }
}

@media (min-width: 576px) {
  .p-topics-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.p-topics-date {
  font-size: 14px;
  margin-right: 44.4px;
}

.p-topics-category-1 {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  margin-right: 15.6px;
  padding-top: 3.8px;
  padding-right: 4.3px;
  padding-bottom: 3.8px;
  padding-left: 13px;
  background-color: #7080bb;
  width: 98px;
  max-width: 100%;
}

.p-topics-category-2 {
  display: inline-block;
  color: #fff;
  font-size: 13px;
  margin-right: 15.6px;
  padding-top: 3.8px;
  padding-right: 1.2px;
  padding-bottom: 3.8px;
  padding-left: 3.7px;
  background-color: #b28db4;
  width: 98px;
  max-width: 100%;
}

.p-topics-text-link {
  color: #101010;
}

.p-topics-text-link:hover {
  text-decoration: underline;
}

.p-topics-text {
  font-size: 14px;
  margin-top: 16.4px;
}

.p-topics-text-link:hover.p-topics-text {
  color: #101010;
  opacity: 0.7;
  -webkit-transform: 0.3s;
  transform: 0.3s;
}

@media (min-width: 576px) {
  .p-topics-text {
    margin-top: 0;
  }
}

.p-topics-button {
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 780px) {
  .p-topics-button {
    text-align: center;
  }
}

@media (max-width: 576px) {
  .p-topics-button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.p-topics-link {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 16rem;
  max-width: 100%;
  padding-top: 11.5px;
  padding-bottom: 13.9px;
  /* border: 2px solid transparent; */
  background-image: -webkit-gradient(linear, left top, right top, from(#b28db4), color-stop(100%, #7080bb), color-stop(25%, #b28db4));
  background-image: linear-gradient(to right, #b28db4 0%, #7080bb 100%, #b28db4 25%);
  color: #fff;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  text-align: center;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  width: 232px;
  max-width: 100%;
}

.p-topics-link:after {
  position: absolute;
  content: "";
  top: 45%;
  left: 68%;
  display: block;
  width: 44px;
  height: 5px;
  background-image: url(../img/lgg_pro_arrow2.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.p-topics-link:focus {
  opacity: 1;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(70%, #cca1c8), color-stop(100%, #cca1c8), color-stop(25%, #8294d6));
  background-image: linear-gradient(to right, #cca1c8 0%, #8294d6 100%, #cca1c8 25%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #fff;
}

.p-topics-link:hover {
  opacity: 1;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(70%, #cca1c8), color-stop(100%, #cca1c8), color-stop(25%, #8294d6));
  background-image: linear-gradient(to right, #cca1c8 0%, #8294d6 100%, #cca1c8 25%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #fff;
}

.p-comment {
  font-style: normal;
  font-size: 12px;
  @include mq() {
    font-size: calc(vw(12)*1.5);
  }
}

